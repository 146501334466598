// Serbian
export default{
    form: {
        startSurveyButton: "Počnimo",
        nextButton: "Sledeće",
        submitAnswerButton: "Pošalji odgovor",
        continueButton: "Nastavi",
        finishButton: "Završi",
        headerPercentageCompletion: 'završeno',
        headerQuestionsCompleted: 'pitanja',
        headerDefaultErrorMessage: 'Ups! Nešto je pošlo po zlu.',
        brandingPoweredBy: 'Pokreće',
        brandingPromoWhySurvey: 'Zašto raditi anketu kad možete koristiti <b>{product}</b>?',
        brandingPromoGetStarted: 'Počnite danas!',
        submissionPending: "Vaš voiceform se šalje. Nemojte zatvarati prozor preglednika.",
        submissionCanCloseWindow: "Vaš voiceform je uspešno poslat. Sada možete zatvoriti prozor 👍",
        mediaAttachmentOverlayCloseButton: "Zatvori",
        expiredFormHeader: "Hvala što ste navratili!",
        expiredFormMessage: "Izgleda da je ova anketa istekla. Molimo kontaktirajte kreatora voiceform-a da ga obavestite.",
        notPublishedFormHeader: "Voiceform nije objavljen!",
        notPublishedFormMessage: "Izgleda da ova anketa nije objavljena. Molimo kontaktirajte kreatora voiceform-a da ga obavestite.",
        notPublishedFormRetryButton: "Pokušaj ponovo",
        errorFormHeader: "Ups!",
        errorFormMessage: "Nešto je možda pošlo po zlu. Molimo pokušajte kasnije.",
        errorFormRetryButton: "Pokušaj ponovo",
        emptyFormHeader: "Hvala što ste navratili!",
        emptyFormMessage: "Ups! Izgleda da je ovaj obrazac trenutno prazan, bez pitanja ili polja za popunjavanje. Izvinjavamo se zbog neprijatnosti. Molimo kontaktirajte kreatora obrasca kako biste ga obavestili o ovom problemu. Hvala vam na razumevanju i saradnji!",

        restoreFormHeader: "Izgleda da imate započet obrazac!",
        restoreFormMessage: "Da li želite da nastavite od mesta gde ste stali?",
        restoreFormButton: "Nastavi obrazac",
        restoreFormButtonNew: "Počni iznova",

        audioPermissionHeader: "Želite li da odgovorite glasom?",
        audioPermissionMessage: "Govorenje će omogućiti brz i lak odgovor.",
        audioPermissionAcceptButton: "Da, želim",
        audioPermissionDenyButton: "Ne, hvala",
        audioPermissionErrorHeader: "Mikrofon nije dostupan",
        audioPermissionErrorMessage: "Molimo proverite dozvole vašeg pregledača da omogućite pristup mikrofonu.",
        audioPermissionErrorButton: "U redu",
        audioRecorderFailedUpload: "Neuspelo učitavanje snimka",
        silenceDetectorError: "Ne čujemo vas. Molimo proverite postavke mikrofona. Savet: Možda je u pitanju vaš spoljašnji mikrofon.",
        audioRecorderButtonRecord: "Započni snimanje",
        audioRecorderButtonRecordMore: "Snimaj više",
        voiceResponseSelectionMessage: "Odaberite kako želite da odgovorite...",
        voiceResponseSelectionOrDivider: "ili",
        voiceResponseTextInputPlaceholder: "Upišite odgovor",
        voiceResponseTextInputCharacterCounterMinMessage: "Unesite najmanje {min} karaktera",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} karaktera (najmanje {min} karaktera)",
        voiceResponseTextInputCharacterValidationMessage: "{count} karaktera (najmanje {min} karaktera)",

        // checkbox
        checkboxValidationTooFew: "Morate odabrati najmanje {min} opciju(e)",
        checkboxValidationTooMany: "Možete odabrati najviše {max} opciju(e)",
        checkboxNoneOfTheAboveOption: "Nijedno od navedenog",
        checkboxInvalidAnswer: "Nevažeći odgovor.",

        // datepicker
        datePickerPlaceholder: "Odaberite datum",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Nevažeći e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Snimaj video",
        fileUploadPluginScreenCast: "Snimanje ekrana",
        fileUploadVideoImportFilesDefault: 'Snimite ili učitajte video putem:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Odaberite opciju za snimanje videa:',
        fileUploadVideoImportFilesNoCamera: 'Pritisnite dugme za snimanje video ekrana',
        fileUploadVideoImportFilesNoScreenCast: 'Pritisnite dugme za snimanje videa',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nema dostupnih opcija za snimanje videa',

        // matrix
        matrixValidationMessage: "Odgovor je validan.",
        matrixRow: "Red",
        matrixColumn: "Kolona",
        matrixRowRequired: "Red {rowTitle} je obavezan.",
        matrixRadioGroup: "Radio grupa",
        matrixCheckboxGroup: "Grupa sa kvačicama",
        matrixGroupRequired: "Za red {rowTitle}. {type} {groupTitle} je obavezno.",
        matrixColumnRequired: "Za red {rowTitle}. Kolona {columnTitle} je obavezna.",
        matrixColumnInvalid: "Za red {rowTitle}. Kolona {columnTitle} je nevažeća.",
        matrixRequired: "Obavezno.",
        matrixNumericMustBeNumber: "Mora biti broj.",
        matrixNumericMustBeGreaterThenMin: "Mora biti veće od {min}.",
        matrixNumericMustBeLessThenMax: "Mora biti manje od {max}.",
        matrixNumericMustBeInteger: "Mora biti ceo broj.",
        matrixNumericInvalidNumber: "Nevažeći broj.",

        // name
        nameLabel: "Ime",
        nameInvalid: "Unesite ime i prezime.",

        // nps
        npsNotLikely: "Vrlo malo verovatno",
        npsExtremelyLikely: "Vrlo verovatno",

        // numeric input
        numericInputRequired: "Obavezno.",
        numericInputMustBeNumber: "Mora biti broj.",
        numericInputMustBeGreaterThenMin: "Mora biti veće od {min}.",
        numericInputMustBeLessThenMax: "Mora biti manje od {max}.",
        numericInputMustBeInteger: "Mora biti ceo broj.",
        numericInputInvalidNumber: "Nevažeći broj.",
        numericInputPlaceholder: "Unesite broj",

        // phone
        phoneInvalid: "Broj telefona nije važeći.",
        phoneCountrySelectorLabel: 'Pozivni broj države',
        phoneCountrySelectorError: 'Odaberite državu',
        phoneNumberLabel: 'Broj telefona',
        phoneExample: 'Primer:',

        // boolean
        booleanYesLabel: "Da",
        booleanNoLabel: "Ne",

        //questionStep
        questionStepAudioQuestionLabel: "Audio Pitanje",

        // errors
        invalidPhoneNumber: "{phone} je nevažeći broj telefona.",
        invalidEmail: "{email} je nevažeća e-mail adresa.",
        questionIsRequired: "Pitanje je obavezno.",
        answerIsNotValid: "Odgovor nije važeći.",
        unfinishedProbingDialogError: "Završite dijalog.",
        cannotResumePartialResponse: "Nije moguće nastaviti delimični odgovor.",
        failedToSubmitForm: "Neuspešno slanje obrasca. Proverite internet konekciju i pokušajte ponovo."
    }
}
